import { PanelOptions, Pod } from './types';

import { Panel } from './components/Panel';

import { IconsEditor } from './components/editors/IconsEditor';
import { ThresholdsEditor } from './components/editors/ThresholdsEditor';
import { NotSupportedText } from './components/editors/NotSupportedText';
import { UseMetricEditor } from './components/editors/UseMetricEditor';

import { PanelPlugin } from '@grafana/data';

export const plugin = new PanelPlugin<PanelOptions>(Panel).setPanelOptions((builder) => {
  return (
    builder
      .addRadio({
        path: 'visualizationType',
        name: 'Pod',
        category: ['Visualization'],
        defaultValue: Pod.GAUGE,
        settings: {
          options: [
            {
              label: 'Gauge',
              value: Pod.GAUGE,
            },
            {
              label: 'Line',
              value: Pod.LINE,
            },
            {
              label: 'Bar',
              value: Pod.BAR,
            },
          ],
        },
      })
      .addCustomEditor({
        id: 'notSupportedText',
        name: 'This visualization is not supported',
        category: ['Visualization'],
        path: '',
        showIf: (config) => config.visualizationType !== Pod.GAUGE,
        editor: NotSupportedText as any,
      })

      .addFieldNamePicker({
        name: 'Metric',
        path: 'gauge.value.metricName',
        category: ['Value'],
        showIf: (config) => config.visualizationType === Pod.GAUGE,
      })
      .addCustomEditor({
        id: 'min',
        name: 'Min',
        path: 'gauge.min',
        category: ['Extremum'],
        defaultValue: { useMetric: false, value: 0 },
        showIf: (config) => config.visualizationType === Pod.GAUGE,
        editor: UseMetricEditor as any,
      })
      .addCustomEditor({
        id: 'max',
        name: 'Max',
        path: 'gauge.max',
        category: ['Extremum'],
        defaultValue: { useMetric: false },
        showIf: (config) => config.visualizationType === Pod.GAUGE,
        editor: UseMetricEditor as any,
      })

      // note: `gauge.unit` will contain unit name, not it's string representation
      // to format value with unit, use `getValueFormat` function from `@grafana/data`
      .addUnitPicker({
        path: 'gauge.unit',
        name: 'Unit',
        category: ['Value Format'],
        showIf: (config) => config.visualizationType === Pod.GAUGE,
      })
      .addNumberInput({
        path: 'gauge.decimals',
        name: 'Decimals',
        settings: {
          placeholder: 'auto',
          min: 0,
          max: 5,
        },
        category: ['Value Format'],
        showIf: (config) => config.visualizationType === Pod.GAUGE,
      })
      .addSliderInput({
        path: 'gauge.valueSize',
        defaultValue: 20,
        name: 'Size (px)',
        settings: {
          min: 1,
          max: 50,
        },
        category: ['Value Format'],
        showIf: (config) => config.visualizationType === Pod.GAUGE,
      })

      .addBooleanSwitch({
        path: 'gauge.reversed',
        name: 'Reversed',
        defaultValue: false,
        category: ['Direction'],
        showIf: (config) => config.visualizationType === Pod.GAUGE,
      })

      .addCustomEditor({
        id: 'icons',
        path: 'gauge.icons',
        name: 'Icons',
        category: ['Icons'],
        defaultValue: [],
        showIf: (config) => config.visualizationType === Pod.GAUGE,
        editor: IconsEditor as any,
      })

      .addCustomEditor({
        id: 'thresholds',
        path: 'gauge.thresholds',
        name: 'Thresholds',
        category: ['Thresholds'],
        defaultValue: {
          defaultColor: '#37872d',
          arcBackground: 'rgba(38, 38, 38, 0.1)',
          thresholds: [],
        },
        showIf: (config) => config.visualizationType === Pod.GAUGE,
        editor: ThresholdsEditor as any,
      })
      .addTextInput({
        path: 'gauge.link',
        name: '',
        category: ['Link'],
        showIf: (config) => config.visualizationType === Pod.GAUGE,
      })
  );
});
