import { FieldNamePicker } from '../../grafana/MatchersUI/FieldNamePicker';

import { StandardEditorProps } from '@grafana/data';
import { HorizontalGroup, InlineField, InlineSwitch, Input } from '@grafana/ui';

import React from 'react';

import * as _ from 'lodash';

type UseMetricConfig = {
  useMetric: boolean;
  value?: number;
  metricName?: string;
};

const fieldNamePickerSettings = {
  settings: { width: 24 },
} as any;

export function UseMetricEditor({ onChange, value, context }: StandardEditorProps<UseMetricConfig>) {
  let config: UseMetricConfig = value;

  const onFieldChange = (field: keyof UseMetricConfig, value: any) => {
    // @ts-ignore
    config[field] = value;

    onChange(config);
  };

  return (
    <HorizontalGroup>
      <InlineField label="Use metric">
        <InlineSwitch
          value={config?.useMetric}
          onChange={(evt) => onFieldChange('useMetric', (evt.target as any).checked)}
        />
      </InlineField>
      <InlineField>
        {config?.useMetric ? (
          <FieldNamePicker
            context={context}
            value={config?.metricName as string}
            onChange={(newVal: any) => onFieldChange('metricName', newVal)}
            item={fieldNamePickerSettings}
          />
        ) : (
          <Input
            placeholder="value"
            value={config?.value}
            width={24}
            onChange={(evt) => onFieldChange('value', (evt.target as any).value)}
          />
        )}
      </InlineField>
    </HorizontalGroup>
  );
}
