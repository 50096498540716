import { Aggregation } from './types';

import * as _ from 'lodash';

export function filterMetricListByAlias(list: any[], alias: string | undefined, option: string): any[] {
  const filteredSeries = _.filter(list, (serie) => serie.alias === alias);
  if (filteredSeries.length === 0) {
    throw new Error(`${option}: Can't find metric for ${alias} name.`);
  }
  if (filteredSeries.length > 1) {
    throw new Error(`${option}: Get ${filteredSeries.length} metrics for ${alias} name. Please choose one.`);
  }
  return filteredSeries;
}

export function getAggregatedValueFromSerie(
  serie: any,
  aggregation = Aggregation.LAST,
  valueIdx: 0 | 1 = 0
): number | null {
  // series types { datapoints: [number, number][]}
  // valueIdx === 0  for Grafana series, valueIdx === 1 for Chartwerk series
  if (serie === undefined) {
    return null;
  }
  if (serie.datapoints.length === 0) {
    return null;
  }
  switch (aggregation) {
    case Aggregation.LAST:
      const lastRow = _.last(serie.datapoints as Array<[number, number]>);
      // @ts-ignore
      return !_.isEmpty(lastRow) ? lastRow[valueIdx] : null;
    default:
      throw new Error(`Unknown aggregation type: ${aggregation}`);
  }
}
