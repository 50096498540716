import { Options } from '../models/options';
import { Series } from '../models/series';

import { PanelOptions } from '../types';

import { DataProcessor } from '../grafana/data_processor';

import { ChartwerkGaugePod } from '@chartwerk/gauge-pod';

import { PanelData, TimeRange, PanelProps } from '@grafana/data';

import React, { useRef } from 'react';
import { css } from 'emotion';
import * as _ from 'lodash';

interface Props extends PanelProps<PanelOptions> {}

export function Panel({ options, data, width, height, timeZone, timeRange, onChangeTimeRange }: Props) {
  const grafanaSeriesList = getGrafanaSeriesList(data, timeRange);
  const series = new Series(grafanaSeriesList, options.gauge.value).getChartwerkSeries();
  const chartwerkOptions = new Options(grafanaSeriesList, options).getChartwerkOptions();
  let chartContainer = useRef(null);
  // we request animation frame here because we need an existing DOM-element at the moment we render the pod
  window.requestAnimationFrame(() => {
    // TODO: switch / case pod type
    const pod = new ChartwerkGaugePod((chartContainer as any).current, series, chartwerkOptions);
    pod.render();
  });

  const isLinkActive = !_.isEmpty(options.gauge.link);
  const chartClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!isLinkActive) {
      return;
    }
    window.open(options.gauge.link, '_self');
  };

  return (
    <div
      ref={chartContainer}
      className={css`
        width: ${width}px;
        height: ${height}px;
        cursor: ${isLinkActive ? 'pointer' : 'default'};
      `}
      onClick={chartClickHandler}
    ></div>
  );
}

function getGrafanaSeriesList(grafanaData: PanelData, timeRange: TimeRange): any[] {
  const processor = new DataProcessor({});
  return processor.getSeriesList({
    dataList: grafanaData.series,
    range: timeRange,
  });
}
