export interface PanelOptions {
  visualizationType: Pod;
  gauge: {
    min: ExtremumOptions;
    max: ExtremumOptions;
    value: ExtremumOptions;
    valueSize: number;
    reversed: boolean;
    thresholds: {
      arcBackground: string;
      defaultColor: string;
      thresholds: Threshold[];
    };
    icons: Icon[];
    decimals?: number;
    unit?: string;
    link?: string;
  };
}

export type ExtremumOptions = {
  useMetric: false;
  value?: number;
  metricName?: string;
};

export type ValueOptions = {
  metricName?: string;
};

export enum IconPosition {
  UPPER_LEFT = 'Upper left',
  MIDDLE = 'Middle',
  UPPER_RIGHT = 'Upper right',
}

export enum Pod {
  LINE = 'Line',
  BAR = 'Bar',
  GAUGE = 'Gauge',
}

export enum Aggregation {
  MIN = 'min',
  MAX = 'max',
  LAST = 'last',
}

export type Threshold = {
  useMetric: boolean;
  value: number;
  metricName: string;
  color: string;
};

export type Icon = {
  conditions: Condition[];
  metrics: string[];
  values: number[];
  position: IconPosition;
  size: number;
  url: string;
};

export enum Condition {
  EQUAL = '=',
  GREATER = '>',
  LESS = '<',
  GREATER_OR_EQUAL = '>=',
  LESS_OR_EQUAL = '<=',
}
